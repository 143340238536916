import axiosInstance from './api';

export class GoogleTextToSpeechService {
  async getSpeechForText(ttsConfiguration, businessProfileId) {
    let requestBody = {
      audioConfig: {
        audioEncoding: 'MP3',
        pitch: ttsConfiguration.pitch,
        speakingRate: ttsConfiguration.speakingRate
      },
      input: {
        text: ttsConfiguration.text
      },
      voice: ttsConfiguration.voice
    }
    let response = await axiosInstance.post(`/businessProfiles/${businessProfileId}/google/textToSpeech`, requestBody);
    return response.data.data;
  }

  async getVoices (businessProfileId) {
    let response = await axiosInstance.get(`/businessProfiles/${businessProfileId}/google/voices`);
    return response.data.data;
  }
}